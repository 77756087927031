<template>
    <v-container class="C1">
        <div @click="navigate('home')" class="logo pa-5">
          <img src="../assets/icon.png" alt="mvs logo" width="130px" height="100px">
        </div>
        <div class="logincard">
            <p class="signin">Sign in</p>
            <v-form>
        <v-container>
        <v-row>
            <v-col
            cols="12"
            sm="12"
            >
           <v-text-field
            background-color='#c9ebff'
            shaped
            v-model="phoneNumber"
            label="Enter your email address/Phone Number"
            filled
           ></v-text-field>
            <div @click="navigate('forgetPassword')" class="forgetPassword">
                <span class="text">Forgot Password</span>
            </div>
           <v-text-field
             @keyup.enter="enterClicked()"
             background-color='#c9ebff'
           shaped
            type='password'
            v-model="password"
            label="Enter your password"
            filled
           ></v-text-field>
            </v-col>
        </v-row>
          <Loading v-if="Loading"/>
        </v-container>
        <div class="formcard">
         <v-btn
        :disabled='Loading'
         @click="Login()"
        large
        dark
        class="pl-10 pr-10"
        color="#1c63ba"
        >
        submit
        </v-btn>
        <p class="mt-6">Don’t have an account yet ?<strong class="signup" @click="navigate('signup')">Sign up</strong> </p>
        <p><strong> or via social</strong> </p>
        <div class="oauth">
         <v-btn
         @click="loginWithGoogle(i)"
         :key="index"
         v-for="(i, index) in icons"
          class="mx-1"
          dark
          :color="color[index]"
          fab
        >
          <v-icon>
            {{ i }}
          </v-icon>
        </v-btn>
        </div>
        </div>
        </v-form>
        </div>
        <p @click="navigate('home')" class="font-weight-thin underline">Back To Home</p>
        <SnackBar/>
    </v-container>
</template>
<script>
import {GoogleAuth} from '../APIs'
import axios from "axios";
import SnackBar from '../components/Snackbar'
import Loading from '../components/Loading'
export default {
  components:{
    SnackBar,
    Loading
  },
    metaInfo: {
    title: `Login`,
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Ecommerce'}
    ]
  },
     data: () => ({
       phoneNumber:"",
       password:"",
      icons: [
        'mdi-facebook',
        'mdi-google',
      ],
      color:[
          '#1c63ba',
          'red'
      ]
    }), 
    methods:{
     async loginWithGoogle(payload){
       if(payload == 'mdi-facebook'){
         this.oauth()
         return
       }
       try{
        const googleUser = await this.$gAuth.signIn()
        let user = googleUser.getAuthResponse()
          this.$store.commit("setLoading", true)
          axios.put(`${GoogleAuth}`,{
            token: user.id_token
          })
          .then(res => {
            this.$store.commit("setUser", res.data.user)
            this.$store.commit("setToken", res.data.token)
            this.$router.push({name: "home"})
          })
           .catch(err => {
            console.log(err.response.data, "GoogleAuth: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          }) 
       }
       catch(e){
          console.log(e, 'err')
          this.$store.commit("setLoading", false)
       }
      },
        navigate(payload){
           this.$router.push({name:payload});
        },
      enterClicked() {
      this.Login();
    },
    oauth(){
      this.$store.commit("setSnackbar", true)
      this.$store.commit("setServerMessage", "Not Available")
    },
    Login(){
      if(this.password == '' || this.phoneNumber == ''){
        this.$store.commit("setSnackbar", true)
        this.$store.commit("setServerMessage", "Fill All The Required Field")
        return
      }
          let payload= {
            route: this.$router,
            phoneNumber: this.phoneNumber,
            password: this.password,
            redirect: this.$route.query.redirect
          }
          this.$store.dispatch('Login', payload)
        }
    },
  computed:{
    Loading() {
      return this.$store.getters.Loading;
    },

  },
}
</script>
<style scoped>
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.C1{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.logincard{
  width: 550px;
  padding: 29px;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  margin-bottom: 50px;
}
.signin{
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1156aa;
}
.forgetPassword{
    text-align:right;
    cursor: pointer;
}
.signup{
    color: #d9ab07;
     cursor: pointer;
}
.text{
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  text-decoration-line: underline;
  color: #1c63ba;
}
.formcard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.oauth{
    display: flex;
    margin: 0px;
}
.underline{
  text-decoration-line: underline;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
.logincard{
  width: 350px;
  padding: 29px;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}
}
</style>